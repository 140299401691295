
/**
 * Icon Source for IconsBase: IconSocialsX-32x32
 * Size: 32x32
 * @category Icons
 * @subcategory Icons Source
 *
 * @component
 */
export default {
  name: 'IconSocialsX-32x32',
};
